<template>
  <section class="top-panel" :style="{ backgroundImage: `url('${image}')` }">
    <div class="text">
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "TipBanner",
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    }
  }
};
</script>
