<template>
  <div
    class="isotope-item"
    :class="[
      tagClasses,
      { 'w2 h2': isFeatured },
      { 'current-featured-item featured stamp': isTopFeatured }
    ]"
    :style="{ backgroundImage: `url('${image}')` }"
  >
    <a v-if="url" v-bind:href="url">
      <div :class="{ video: hasVideo }"></div>
      <div class="overlay"></div>
      <div class="text">
        <h4>{{ tileTitle }}</h4>
        <p>{{ description }}</p>
      </div>
    </a>

    <router-link v-else :to="{ path: slug }">
      <div :class="{ video: hasVideo }"></div>
      <div class="overlay"></div>
      <div class="text">
        <h4>{{ tileTitle }}</h4>
        <p>{{ description }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Tile",
  props: {
    tileTitle: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    isFeatured: {
      type: Boolean,
      default: false
    },
    hasVideo: {
      type: Boolean,
      default: false
    },
    slug: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    tags: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isTopFeatured: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tagClasses() {
      return this.tags.map(item => item.replace(/ /g, "_"));
    }
  }
};
</script>
