<template>
  <div class="layout-tip" v-if="getTip">
    <div
      class="share-tip-modal modal fade"
      id="shareTipModal"
      aria-hidden="true"
    ></div>
    <TipTile
      :title="getTip.title"
      :description="getTip.description"
      :image="getTip.image"
    />
    <nav class="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">Ride to live</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'tips' }">Tips</router-link>
        </li>
        <li class="category first last">
          <span v-for="tag in getTip.type" :key="tag">
            <router-link
              :to="{ name: 'tips', query: { filter: getTagLinks(tag) } }"
              >{{ tag }}</router-link
            >
          </span>
        </li>
      </ul>
    </nav>
    <article>
      <div id="content" v-html="$options.filters.fixMarkup(getTip.body)"></div>
      <TipPoll v-if="getTip.isPoll" />
      <div class="social-buttons">
        <ul>
          <li>
            <SocialShare socialMediaType="facebook" />
          </li>
          <li class="last">
            <a :href="getMailLink()" class="email">Email</a>
          </li>
        </ul>
      </div>
    </article>

    <div class="suggested-tips">
      <h3 class="title">Suggested tips</h3>
      <div class="inner">
        <section class="tips-feature">
          <div
            v-if="getTip && getRelatedTip"
            class="isotope-item current-featured-item featured w2 h2"
            :style="{ backgroundImage: `url('${getRelatedTip.image}')` }"
          >
            <router-link :to="{ path: getRelatedTip.path }">
              <div class="overlay"></div>
              <div class="text">
                <h4>{{ getRelatedTip.title }}</h4>
                <p>{{ getRelatedTip.description }}</p>
              </div>
            </router-link>
          </div>
          <div class="breakout-boxes">
            <PollTile />
            <SkillTile />
          </div>
        </section>
      </div>
    </div>
    <div class="more-tips">
      <router-link :to="{ name: 'tips' }">More tips</router-link>
    </div>
    <Preloader :loading="getIsLoading" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { FETCH_TIP_BY_ID, FETCH_TIP_LANDING } from "@/store/actions-type";
import {
  GET_TIP_BY_SLUG,
  GET_FEATURED_TIPS_BY_TAG,
  GET_METADATA,
  GET_IS_LOADING
} from "@/store/getters-type";

import TipTile from "@/components/TipTile.vue";
import SkillTile from "@/components/SkillTile.vue";
import PollTile from "@/components/PollTile.vue";
import Preloader from "@/components/Preloader.vue";
import SocialShare from "@/components/SocialShare.vue";
import TipPoll from "@/components/TipPoll.vue";

export default {
  name: "tipDetail",

  components: {
    TipTile,
    SkillTile,
    Preloader,
    SocialShare,
    TipPoll,
    PollTile
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.getIsLoading ? [{ content: "is loading..." }] : this.getMeta
    };
  },

  methods: {
    ...mapActions({
      fetchTip: `tipDetails/${FETCH_TIP_BY_ID}`,
      ensureLandingTipsLoaded: `tipLanding/${FETCH_TIP_LANDING}`
    }),
    getTagLinks(value) {
      return value.replace(/ /g, "_");
    },
    getMailLink() {
      const link = `
      mailto:?subject=Tip from ridetolive.nsw.gov.au&body=Hi,%0D%0A%0D%0A
      Check out what I found on ridetolive.nsw.gov.au.%0D%0A%0D%0A
      Find out about ${this.getTip.title}%0D%0A%0D%0A%0D%0A%0D%0A.`;
      return link;
    }
  },
  computed: {
    getRelatedTip() {
      return this.$store.getters[`tipLanding/${GET_FEATURED_TIPS_BY_TAG}`](
        this.getTip.type[0],
        this.getTip
      );
    },
    getTip() {
      return this.$store.getters[`tipDetails/${GET_TIP_BY_SLUG}`](
        this.$route.path
      );
    },
    getIsLoading() {
      return this.$store.getters[`tipDetails/${GET_IS_LOADING}`];
    },
    getMeta() {
      return this.$store.getters[`tipDetails/${GET_METADATA}`](
        this.$route.path
      );
    }
  },
  filters: {
    fixMarkup(value) {
      const pattern = /^<div class="content".*/;
      if (pattern.test(value) === false) {
        return `<div class="content">${value}</div>`;
      }
      return value;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.ensureLandingTipsLoaded();
      vm.fetchTip(to.fullPath);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchTip(to.fullPath).then(next);
  }
};
</script>
