<template>
  <div class="finished">
    <h2>Thanks for taking part</h2>
    <p>
      We'll use your answers to gain a deeper insight into what NSW riders
      think, need and do - and tailor futures articles accordingly.
    </p>
  </div>
</template>

<script>
export default {
  name: "TipPollFinished"
};
</script>
