<template>
  <div class="isotope-item h2 breakout-box poll">
    <router-link to="/tips/poll">
      <div class="bottom">
        <div class="text">
          <h4>Poll</h4>
          <p>Take the Ride To Live poll – a few simple questions</p>
          <button class="button">Submit answer</button>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "PollTile",
  props: {}
};
</script>
