<template>
  <div id="content">
    <div class="layout-poll poll-container">
      <div class="poll" v-if="getCurrentQuestion">
        <h2>
          {{ getCurrentQuestion.title }}
        </h2>
        <ul class="questions">
          <li
            v-for="(option, index) in getCurrentQuestion.options"
            :key="index"
          >
            <a href @click.prevent="setOption(option)"
              ><span>{{ option }}</span></a
            >
          </li>

          <li v-if="getCurrentQuestion.type === 'webform_radios_other'">
            <a href @click.prevent="setOption('OTHER')">
              <div style="display: table; height: 100%; width: 100%;">
                <div style="display: table-cell;vertical-align: middle;">
                  <span style="display: inline;">Other</span>
                  <input
                    type="text"
                    name="fname"
                    v-model="otherValue"
                    @click.stop.prevent="setOtherOption"
                    style="display: table-cell; margin: 0 auto; border: 1px solid #CCC !important; font-weight: normal; padding-left: 10px;"
                  />
                </div>
              </div>
            </a>
          </li>
        </ul>
        <div class="progress">
          <p>
            <span>{{ this.currentStep }}</span
            >|
            <span>{{ getTotalCount }}</span>
          </p>
        </div>
      </div>

      <TipPollFinished v-if="finished" />
    </div>
  </div>
</template>

<script>
import { tipsService } from "@/api/services";
import TipPollFinished from "@/components/TipPollFinished.vue";
import { mapGetters } from "vuex";
import { GET_TIPS_POLL } from "@/store/getters-type";

export default {
  name: "TipPoll",
  components: { TipPollFinished },
  props: {},
  data() {
    return {
      currentStep: 1,
      finished: false,
      elementMapping: [
        "what_section",
        "what_content_would",
        "have_you_used",
        "do_the_online"
      ],
      otherValue: null,
      results: {}
    };
  },
  computed: {
    ...mapGetters({
      getFields: `tipDetails/${GET_TIPS_POLL}`
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    getCurrentQuestion: function() {
      const question = {};
      // Find out which element should be displayed by the step count.
      const currentElementMapping = this.getCurrentElementMapping;
      // Get the element JSON object.
      if (
        this.getFields &&
        this.getFields[currentElementMapping] &&
        this.finished === false
      ) {
        const currentElement = this.getFields[currentElementMapping];
        // Get a question object.
        question.title = currentElement["#title"];
        question.options = currentElement["#options"];
        question.type = currentElement["#type"];
        return question;
      }
    },
    getTotalCount: function() {
      return this.elementMapping.length;
    },
    getCurrentElementMapping: function() {
      return this.elementMapping[this.currentStep - 1];
    }
  },
  methods: {
    setOption: function(option) {
      const currentElementMapping = this.getCurrentElementMapping;
      if (option === "OTHER") {
        if (!this.otherValue) {
          return;
        }
        option = this.otherValue;
      }
      this.results[currentElementMapping] = option;
      this.currentStep++;

      if (this.currentStep > this.getTotalCount) {
        this.finished = true;
        this.submitPoll();
      }
    },
    setOtherOption: function() {
      // Leave empty.
    },
    submitPoll: function() {
      const body = this.results;
      body.webform_id = "tips_poll";
      tipsService.createTipsPoll("/webform_rest/submit", body);
    }
  }
};
</script>
