<template>
  <div class="isotope-item stamp h2 breakout-box test ng-scope">
    <a href="/hpt/index.html" target="_self">
      <div class="bottom">
        <div class="text">
          <h4>Test your skills</h4>
          <p>Will your skills and reactions stand up to our virtual ride?</p>
          <button class="button">Start</button>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "SkillTile",
  props: {}
};
</script>
