<template>
  <div class="layout-tips">
    <TipBanner v-bind="tipsBanner.banner" />
    <nav class="filters">
      <h3 class="el-offscreen">Categories</h3>
      <form>
        <select name="categories" @change="filter($event)">
          <option value="show_all" :selected="selected == 'show_all'"
            >All tips</option
          >
          <option value="safety" :selected="selected == 'safety'"
            >Safety</option
          >
          <option value="skills" :selected="selected == 'skills'"
            >Skills</option
          >
          <option value="rules" :selected="selected == 'rules'">Rules</option>
          <option value="watch_the_ads" :selected="selected == 'watch_the_ads'"
            >Watch the ads</option
          >
        </select>
      </form>
      <ul>
        <li>
          <a
            href
            @click.prevent="filter('show_all')"
            :class="{ active: selected == 'show_all' }"
          >
            All Tips
            <span class="el-offscreen"></span>
          </a>
        </li>
        <li>
          <a
            href
            @click.prevent="filter('safety')"
            :class="{ active: selected == 'safety' }"
          >
            Safety
            <span class="el-offscreen">-</span>
          </a>
        </li>
        <li>
          <a
            href
            @click.prevent="filter('skills')"
            :class="{ active: selected == 'skills' }"
          >
            Skills
            <span class="el-offscreen">-</span>
          </a>
        </li>
        <li>
          <a
            href
            @click.prevent="filter('rules')"
            :class="{ active: selected == 'rules' }"
          >
            Rules
            <span class="el-offscreen">-</span>
          </a>
        </li>
        <li>
          <a
            href
            @click.prevent="filter('watch_the_ads')"
            :class="{ active: selected == 'watch_the_ads' }"
          >
            Watch the Ads
            <span class="el-offscreen">-</span>
          </a>
        </li>
      </ul>
    </nav>
    <section class="articles">
      <section class="tips-feature">
        <Tile
          v-if="getFeaturedTip"
          :tileTitle="getFeaturedTip.title"
          :description="getFeaturedTip.description"
          :hasVideo="false"
          :isFeatured="true"
          :slug="getFeaturedTip.path"
          :image="getFeaturedTip.image"
          :isTopFeatured="true"
        />
        <div class="breakout-boxes">
          <PollTile />
          <SkillTile />
        </div>
      </section>
      <isotope
        ref="cpt"
        id="root_isotope"
        :list="tipLanding"
        @filter="filterOption = arguments[0]"
        :options="option"
      >
        <Tile
          v-for="tip in tipLanding"
          :key="tip.id"
          :tileTitle="tip.title"
          :description="tip.description"
          :hasVideo="tip.hasVideo"
          :isFeatured="tip.isFeatured"
          :slug="tip.path"
          :image="tip.image"
          :tags="tip.tags | tagClasses"
        ></Tile>
      </isotope>
    </section>
    <Preloader :loading="getIsLoading" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { SET_SELECTED_FILTER } from "@/store/mutation-type";
import { FETCH_TIP_LANDING } from "@/store/actions-type";
import {
  GET_LANDING_TIPS,
  GET_LANDING_BANNER,
  GET_METADATA,
  GET_IS_LOADING,
  GET_FIRST_FEATURED_TIP_BY_TAG
} from "@/store/getters-type";
import Preloader from "@/components/Preloader.vue";
import Tile from "@/components/Tile.vue";
import TipBanner from "@/components/TipBanner.vue";
import SkillTile from "@/components/SkillTile.vue";
import PollTile from "@/components/PollTile.vue";
import isotope from "vueisotope";
// eslint-disable-next-line no-unused-vars
import packery from "isotope-packery";
import VueScrollTo from "vue-scrollto";
export default {
  name: "tips",
  components: {
    Tile,
    TipBanner,
    Preloader,
    isotope,
    SkillTile,
    PollTile,
    // eslint-disable-next-line vue/no-unused-components
    VueScrollTo
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.getIsLoading ? [{ content: "is loading..." }] : this.getMeta
    };
  },
  data() {
    return {
      filterOption: "show_all",
      option: {
        transitionDuration: "0.35s",
        layoutMode: "packery",
        itemSelector: ".isotope-item",
        getFilterData: {
          show_all: el => {
            return el.id !== this.getFeaturedTip.id;
          },
          skills: el => {
            return (
              el.tags.includes("skills") && el.id !== this.getFeaturedTip.id
            );
          },
          rules: el => {
            return (
              el.tags.includes("rules") && el.id !== this.getFeaturedTip.id
            );
          },
          safety: el => {
            return (
              el.tags.includes("safety") && el.id !== this.getFeaturedTip.id
            );
          },
          watch_the_ads: el => {
            return (
              el.tags.includes("watch the ads") &&
              el.id !== this.getFeaturedTip.id
            );
          }
        }
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.filter) {
        this.filter(this.$route.query.filter);
      } else {
        this.filter("show_all");
      }
    });
  },
  filters: {
    tagClasses(value) {
      return value.map(item => item.replace(/ /g, "_"));
    }
  },
  computed: {
    ...mapState({
      getTips: state => state.tipLanding.landingTips,
      selected: state => state.tipLanding.selected
    }),
    ...mapGetters("tipLanding", {
      tipLanding: `${GET_LANDING_TIPS}`,
      tipsBanner: `${GET_LANDING_BANNER}`,
      getIsLoading: `${GET_IS_LOADING}`,
      getMeta: `${GET_METADATA}`,
      getFeaturedTip: `${GET_FIRST_FEATURED_TIP_BY_TAG}`
    })
  },
  methods: {
    ...mapActions({
      ensureLandingTipsLoaded: `tipLanding/${FETCH_TIP_LANDING}`
    }),
    ...mapMutations("tipLanding", {
      setSelectedFilter: `${SET_SELECTED_FILTER}`
    }),
    filter: function(key) {
      if (typeof key === "object") {
        key = event.target.value;
      }
      this.setSelectedFilter(key);
      this.$refs.cpt.filter(this.selected);
      const elem = document.querySelector(".filters");
      VueScrollTo.scrollTo(elem);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.ensureLandingTipsLoaded();
    });
  }
};
</script>
